<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active>{{ scope.name }}</CBreadcrumbItem>
    </teleport>
    <div v-if="!loading" class="scope-upload" :aria-disabled="uploading">
        <div class="scope-upload__content">
            <div class="scope-upload__scope-info">
                <h1 class="scope-upload__scope-name">{{ scope.name }}</h1>
                <p class="scope-upload__scope-updated">{{ lastScopeUpdate }}</p>
            </div>
            <div class="scope-upload__scope-summary">
                <span v-for="(value, label) in scopeSummary" :key="label" class="scope-upload__scope-summary-item">
                  {{ label }}: <strong>{{ value }}</strong>
                </span>
            </div>
            <div class="scope-upload__table scope-upload__table_top">
                <h3 class="scope-upload__table-title">
                    <img src="@/assets/images/crown.svg" alt="crown"/>
                    Primary Documents
                </h3>
                <p class="scope-upload__table-info">
                    These documents are required before the flow down process can start
                </p>
                <ScopeLoadingTable :key="itemsUpdated" :primary="true" :items="mainTasks" @open-modal="onModalOpen"
                                   @file-loaded="onFileLoaded" @file-deleted="onFileDelete"/>
            </div>

            <div class="scope-upload__table">
                <h3 class="scope-upload__table-title">Documents</h3>
                <p class="scope-upload__table-info">
                    These documents are required and are part of the flow down process
                </p>
                <ScopeLoadingTable :key="itemsUpdated" :primary="false" :items="otherTasks" @open-modal="onModalOpen"
                                   @file-loaded="onFileLoaded" @file-deleted="onFileDelete"/>
            </div>

            <div class="scope-upload__footer">
                <CButton variant="outline" color="primary" @click="onCancel" :disabled="hasTasksToSave">
                    Cancel
                </CButton>
                <CButton color="primary" @click="submit" :disabled="uploading">
                    {{ uploading ? 'Saving...' : 'Save uploaded document(s)' }}
                    <CSpinner size="sm" v-if="uploading"/>
                </CButton>
                <span class="scope-upload__footer-counter">
                    <strong>{{ doneTasksAmount }}</strong> Document(s) Uploaded
                </span>
                <router-link :to="{ name: 'ProjectDocuments', params: {id: $route.params.id} }"
                             class="scope-upload__footer-link">
                    <img src="@/assets/images/upload.svg" alt="upload"/>
                    View Uploaded Documents
                    <img class="scope-upload__footer-arrow" alt="chevron up"
                         src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='303C54'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"/>
                </router-link>
            </div>

        </div>
    </div>

    <div v-else class="scope-upload__loading">
        <CSpinner/>
    </div>

    <CModal class="overflow-modal" :visible="modals.noTasks" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-warning"/>
            </div>
            <h4 class="app-modal__title">No new documents uploaded</h4>
            <p class="app-modal__text">
                Please upload a document
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.noTasks = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.validationContractExtension" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">File not supported!</h4>
            <p class="app-modal__text">
                Oops, this file format is not recognized yet by our platform. Currently only pdf format is supported.
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.validationContractExtension = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.validationRequestExtension" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">File not supported!</h4>
            <p class="app-modal__text">
                Oops, this file format is not recognized yet by our platform.
                Here is the list of the supported formats: doc, docx, xlsx, xls, pdf, jpg, jpeg, gif, emp, svg, txt, png
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.validationRequestExtension = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.validationSize" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">File not supported!</h4>
            <p class="app-modal__text">
                Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.validationSize = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.error" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-face-dead"/>
            </div>
            <h4 class="app-modal__title">Oops, something went wrong!</h4>
            <p class="app-modal__text">
                Please try again.
            </p>
            <div class="app-modal__buttons">
                <CButton color="primary" @click="modals.error = false">Got it!</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="modals.cancel" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <CIcon class="scope-upload__modal-icon" name="cil-warning"/>
            </div>
            <h4 class="app-modal__title">Cancel changes?</h4>
            <p class="app-modal__text">Are you sure you want to cancel changes?</p>
            <div class="app-modal__buttons">
                <CButton variant="outline" color="primary" @click="modals.cancel = false">Cancel</CButton>
                <CButton color="primary" @click="onCancelConfirm">Confirm</CButton>
            </div>
        </div>
    </CModal>

    <CModal class="overflow-modal" :visible="!!modals.congrats" backdrop="static">
        <div class="app-modal scope-upload__modal">
            <div class="app-modal__icon">
                <img class="scope-upload__modal-icon" src="@/assets/images/well-done.svg" alt="Done"/>
            </div>
            <h4 class="app-modal__title">Congratulations!</h4>
            <p class="scope-upload__modal-text">
                The primary contract has been fully executed. Be sure all additional documents are uploaded shortly.
                The flow down process has now started.
            </p>
            <ul class="scope-upload__modal-list">
                <li v-for="document in modals.congrats" :key="document.id">
                    {{ document.name }} ({{ translate(document.type) }})
                    {{ checkDateValid(document.due_date) ? '- By ' + formatDate(document.due_date) : '' }}
                </li>
            </ul>
            <p class="scope-upload__modal-text">
                View all flow down and recurrent settings -
                <router-link :to="{name: 'ProjectTasksList', params: {id: $route.params.id }}">
                    Access flowdown
                </router-link>
            </p>
            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" color="primary" @click="modals.congrats = false">Got it!</CButton>
            </div>
        </div>
    </CModal>
    <ConfirmDialog ref="confirmDialog"/>
</template>

<script>
import ScopeLoadingTable from "@/components/Tables/ScopeLoadingTable.vue";
import moment from "moment/moment";
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import CSelectSearch from "@/components/CSelectSearch.vue";
import unsavedChanges from "@/mixin/unsavedChanges";
import Scopes from "@/api/v2/endpoints/Scopes";
import Tasks from "@/api/v2/endpoints/Tasks";
import {
    CLOSED_TASKS,
    COMPLETED,
    DONE_TASKS,
    FULLY_EXECUTED,
    PENDING_SUBMISSION,
    SIGNATURE_REQUIRED
} from "@/domain/Entities/Task/taskStatuses";
import {SECONDARY_TASK} from "@/domain/Entities/Task/taskPriorityTypes";

const vocabulary = {
    "Contracts": 'Requested signature',
    "Request documents": 'Requested document',
    "Acknowledge": 'Requested acknowledgement'
}

export default {
    name: "ScopeUpload",
    components: {CSelectSearch, ScopeLoadingTable, ConfirmDialog},
    mixins: [unsavedChanges],
    inject: ['toast'],
    async beforeRouteLeave() {
        return this.triggerUnsavedChangesCheck(this.hasTasksToSave);
    },
    data() {
        return {
            loading: false,
            uploading: false,
            mountedComponent: false,
            flowdownHasStarted: false,
            itemsUpdated: 1,

            scopeId: null,
            scope: {},
            allTasks: [],
            mainTasks: [],
            otherTasks: [],

            modals: {
                noTasks: false,
                validationContractExtension: false,
                validationRequestExtension: false,
                validationSize: false,
                cancel: false,
                error: false
            },
        }
    },
    computed: {
        tasksToSave() {
            return [...this.mainTasks, ...this.otherTasks]
                .filter(task => !CLOSED_TASKS.includes(task.status) && task.local_file);
        },
        hasTasksToSave() {
            return this.tasksToSave.length > 0;
        },
        doneTasksAmount() {
            return this.allTasks.filter(task => DONE_TASKS.includes(task.status)).length;
        },
        lastScopeUpdate() {
            return `Last Updated by ${this.scope.last_activity.user_name} ${moment(this.scope.last_activity.created_at).format('MM/DD/YYYY hh:mm A')}`
        },
        scopeSummary() {
            return {
                'Company': this.scope.external_company.name,
                'Project': this.scope.project.name,
                'Scenario': this.scope.template_folder.template_collection.name,
                'Package': this.scope.template_folder.name,
                'Default Signer (Sub)': this.scope.external_signer.full_name,
            };
        },
    },
    watch: {
        allTasks(newList, oldList) {
            this.flowdownHasStarted = newList.length > oldList.length && oldList.length > 0;
        }
    },
    created() {
        this.scopeId = this.$route.params.scopeId

        this.setUpPage();
    },
    mounted() {
        this.mountedComponent = true;
    },
    methods: {
        setUpPage() {
            this.toggleLoading();

            this.loadScope()
                .then(() => this.loadScopeTasks())
                .finally(() => this.toggleLoading());
        },
        loadScope() {
            return Scopes
                .show(this.scopeId, {
                    scope_data: 'name,status,step',
                    with_project: 'id,name',
                    with_external_company: 'name',
                    with_external_signer: 'full_name',
                    with_template_collection: 'name',
                    with_template_folder: 'name',
                    with_last_activity: 'all'
                })
                .then(response => this.scope = response.data);
        },
        loadScopeTasks() {
            return Tasks
                .index(this.scope.project.id, {
                    scope_id: this.scopeId,
                    task_data: 'id,name,type,due_date,is_main,status,priority_type',
                    only_statuses: [SIGNATURE_REQUIRED, PENDING_SUBMISSION, FULLY_EXECUTED, COMPLETED],
                    with_upload: 'all'
                })
                .then(response => {
                    this.allTasks = response.data;
                    this.mainTasks = response.data.filter(task => task.is_main);
                    this.otherTasks = response.data.filter(task => !task.is_main);
                });
        },
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY')
        },
        translate(key) {
            return vocabulary[key]
        },
        onFileLoaded(payload) {
            const {file, taskId, isPrimary} = payload;
            const filesType = isPrimary ? 'mainTasks' : 'otherTasks';
            const taskToLoadIndex = this[filesType].findIndex(task => task.id === taskId);

            if (taskToLoadIndex < 0) {
                return;
            }

            this[filesType][taskToLoadIndex]['local_file'] = file;
        },
        onFileDelete(payload) {
            const {taskId, isPrimary} = payload;
            const filesType = isPrimary ? 'mainTasks' : 'otherTasks';
            const taskToLoadIndex = this[filesType].findIndex(task => task.id === taskId);

            if (taskToLoadIndex < 0) {
                return;
            }

            this[filesType][taskToLoadIndex]['local_file'] = null;
        },
        onModalOpen(modalName) {
            if (modalName === 'validation: Contracts') this.modals.validationContractExtension = true;
            if (modalName === 'validation: Request documents') this.modals.validationRequestExtension = true;
            if (modalName === 'validation: size') this.modals.validationSize = true;
        },
        submit() {
            this.toggleUploading();

            this.uploadTaskDocuments()
                .then(() => this.loadScopeTasks())
                .then(() => this.checkAndOpenFlowdownTasksModal())
                .catch(() => this.modals.error = true)
                .finally(() => this.toggleUploading());
        },
        uploadTaskDocuments() {
            return Scopes
                .uploadTaskDocuments(this.scopeId, this.defineUploadTaskDocumentsData())
                .then(() => this.toast('success', 'Document(s) Uploaded'));
        },
        defineUploadTaskDocumentsData() {
            if (!this.hasTasksToSave) {
                return this.modals.noTasks = true;
            }

            const formData = new FormData();

            this.tasksToSave.forEach((task, i) => {
                formData.append(`tasks[${i}][id]`, task.id)
                formData.append(`tasks[${i}][file]`, task.local_file)
            })

            return formData;
        },
        checkAndOpenFlowdownTasksModal() {
            const flowdownTasks = this.otherTasks
                .filter(task => task.priority_type === SECONDARY_TASK && !DONE_TASKS.includes(task.status));

            if (flowdownTasks.length > 0 && this.flowdownHasStarted) {
                this.modals.congrats = flowdownTasks;
            }
        },
        onCancel() {
            if (this.hasTasksToSave)
                this.modals.cancel = true
        },
        onCancelConfirm() {
            this.modals.cancel = false;

            this.loadScopeTasks();

            this.itemsUpdated++;
        },
        checkDateValid(date) {
            return moment(date).isValid();
        },
        toggleLoading() {
            this.loading = !this.loading;
        },
        toggleUploading() {
            this.uploading = !this.uploading;
        }
    }
}
</script>

<style lang="scss">
.scope-upload {
    background: white;
    border-radius: 6px;
    padding: 24px;
    transition: 0.3s;

    &__modal {
        width: 688px;

        &-icon {
            height: 80px !important;
            width: 80px !important;
            color: #BAC6CF;
        }

        &-text {
            font-size: 16px;
            color: #677A89;
        }

        &-list {
            font-size: 16px;
            color: #677A89;
            margin: 40px 0;
            padding: 0 20px;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;

        height: calc(100vh - 260px);

        & .spinner-border {
            color: #0068ad;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__scope {

        &-name {
            font-weight: 600;
            font-size: 24px;
            margin-bottom: 8px;
            color: #1C262F;
            border-bottom: 1px solid #C3CDD5;
        }

        &-updated {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 12px;
            color: #677A89;
        }

        &-summary {
            display: flex;
            align-items: center;
            column-gap: 32px;
            flex-wrap: wrap;

            background: #F2F5F8;
            border-radius: 8px;
            padding: 5px 16px;
            min-height: 40px;

            &-item {
                font-size: 14px;
                color: #677A89;
            }
        }
    }

    &__table {

        &_top {
            border-bottom: 1px solid #C3CDD5;
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 4px;

            color: #1C262F;
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 7px;
        }

        &-info {
            color: #677A89;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 24px;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 16px;

        border-top: 1px solid #C3CDD5;
        padding: 24px 0 0 0;

        &-arrow {
            height: 10px;
            transform: rotate(-90deg);
        }

        &-link {

        }

        &-counter {
            margin-left: auto;
            color: #677A89;
            font-size: 16px;
        }
    }
}
</style>
